<template>
  <div>
    <v-overlay :value="overlay" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-data-table
      :headers="headers"
      :items="dataTable"
      :search="search"
      :loading="loadingTable"
      :disable-sort="$vuetify.breakpoint.name == 'xs'"
      :key="`list-users-${tenantId}`" 
      :items-per-page="15"
      :options.sync="options"
      loading-text="Cargando notidicaciones ws..."
      no-results-text="No se han encontrado notidicaciones ws"
      no-data-text="Sin notidicaciones ws"
      class="elevation-1"
      dense
    >
    
      <template v-slot:top>
        <v-row align="center" justify="center">
          <v-col cols="12" md="3" class="text-center">
            <v-toolbar-title>
              <v-icon>mdi-account-switch</v-icon>USUARIOS DEL SISTEMA
            </v-toolbar-title>
          </v-col>
          <v-col md="5">
            <v-text-field v-model="search" label="Buscar" />
          </v-col>
          <v-col md="2" class="text-center">
            <v-btn small color="info" @click="notificacionesWs">
              <v-icon>mdi-checkbox-marked-outline</v-icon> Notificaciones ws
            </v-btn>
          </v-col>
          <v-col md="2" class="text-center">
            <v-btn small color="primary" @click="editUser">
              <v-icon>mdi-plus</v-icon> Nuevo usuario
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.status="{ item }">
        {{ item.status != null ? item.status.toUpperCase() : "" }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="editUser(item.party_id)"
            >
              <v-icon color="orange">mdi-account-edit</v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="gestionaNotificaciones(item)"
            >
              <v-icon color="info">mdi-bell-ring-outline</v-icon>
            </v-btn>
          </template>
          <span>Notificaciones</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <PartyComponent
      v-if="dialog"
      :availableItems="availableItems"
      :dialog="dialog"
      :dataParty="dataParty"
      roleDefault="_NA_"
      @closeDialog="dialog = !dialog"
      @savedNewParty="savedNewParty"
    />
    <v-dialog
      v-model="dialogNotificacionesWs" 
      persistent 
      max-width="700px"
    >
      <NotificacionesWsComponentVue 
        @closeDialogNotificacionesWs="dialogNotificacionesWs = false" 
      />
    </v-dialog>
    <v-dialog
      v-model="dialogListadoServicioWhatsApi" 
      persistent 
      max-width="500px"
    >
      <v-data-table
        v-model="notificacionesWsSelected"
        :headers="headersNotificacionesWs"
        :items="itemsNotificacionesWs"
        class="elevation-1"
        :search="searchNotificacionesWs"
        item-key="notification_ws_id"
        show-select
        dense
        :fixed-header="true"
        :hide-default-footer="true"
        disable-pagination
      >
        <template v-slot:top>
          <v-toolbar dark color="blue" height="60" flat>
              Notificaciones
              <v-spacer></v-spacer>
              <v-text-field 
                v-model="searchNotificacionesWs" 
                label="Buscar" 
                :hide-details="true"
                append-icon="mdi-magnify"
              />
              <v-spacer></v-spacer>
              <v-icon @click="dialogListadoServicioWhatsApi= false">mdi-close</v-icon>
          </v-toolbar>
        </template> 
        <template v-slot:footer>
          <v-col md="12" class="text-center">
            <v-btn
              color="primary"
              @click="guardarNotificacionUser"
              :loading="loadingBtn"
            >
              GUARDAR
            </v-btn>
          </v-col>
        </template>
      </v-data-table>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import ExpandableFilters from "../general/ExpandableFilters";
import PartyComponent from "../general/PartyComponent";
import NotificacionesWsComponentVue from './NotificacionesWsComponent.vue';

export default {
  name: "ListUsersComponent",
  components: {
    ExpandableFilters,
    PartyComponent,
    NotificacionesWsComponentVue
  },
  data: () => ({
    dataTable: [],
    setfilters: [],
    dialog: false,
    overlay: false,
    dialogNotificacionesWs:false,
    search: "",
    dataParty: {},
    availableItems: {},
    options: {},
    currentPage: 1,
    headersNotificacionesWs:[
      { text: "Notificación", align: "start", value: "description" },
      { text: "Canal", align: "start", value: "channel" }
    ],
    defaultAvailableItems: {
      generalData: true,
    },
    headers: [
      { text: "Código", align: "start", value: "party_id" },
      { text: "Nombre", align: "start", value: "name" },
      { text: "Usuario", align: "start", value: "user_login_id" },
      { text: "Identificación", align: "start", value: "identification" },
      { text: "Estado", align: "start", value: "status" },
      { text: "Actions", align: "start", value: "actions", align: "center" },
    ],
    itemsNotificacionesWs:[],
    userNotificacionesWs:null,
    filtroHabilitado: true,
    searchNotificacionesWs:null,
    dialogListadoServicioWhatsApi:false,
    notificacionesWsSelected:[],
    partyId:null
  }),
  watch: {
    options: {
      handler() {
        this.getUsers();
      },
      deep: true,
    },
  },
  computed: {

    ...mapState("master", ["loadingTable", "tenantId","loadingBtn"]),

    ...mapState("administration", ["menu"])
    
  },
  methods: {

    ...mapMutations("master", [
      "setMenu",
      "setUrl",
      "setLoadingTable",
      "setLoadingBtn",
      "setTitleToolbar",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    getUsers() {

      this.setLoadingTable(true);

      this.dataTable = [];

      this.setUrl("lista-partys");

      this.requestApi({
        method: "GET",
        data: {
          typeList: "users",
          habilitado: this.filtroHabilitado,
          page: this.currentPage,
        },
      })
      .then((res) => {
        this.dataTable = res.data._embedded.lista_partys;
      })
      .then(() => {
        this.setLoadingTable(false);
      })

    },

    editUser(party_id) {
      let noPartyId = typeof party_id == "object";
      this.overlay = true;
      this.setUrl(`lista-partys/${noPartyId ? 0 : party_id}`);

      this.requestApi({
        method: "PATCH",
        data: {
          path: this.$route.fullPath,
        },
      })
        .then((res) => {
          this.dataParty = res.data;
          this.availableItems = noPartyId
            ? { generalData: true }
            : this.defaultAvailableItems;
          this.dialog = true;
        })
        .then(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        });
    },

    savedNewParty(data) {
      this.availableItems = this.defaultAvailableItems;
      if (data.newParty) {
        this.dialog = false;
        this.editUser(data.partyId);
      }
      this.getUsers(this.filters);
    },

    notificacionesWs(){

      this.dialogNotificacionesWs = true

    },

    gestionaNotificaciones({user_login_id}){

      this.setUrl('lista-partys')
      this.requestApi({
        method: "GET",
        data: {
          typeList: "NotificacionesWs",
          user_login_id,
          status: true,
        },
      })
      .then(res => {
        
        this.userNotificacionesWs = user_login_id
        this.itemsNotificacionesWs= res.data._embedded.lista_partys[0]
        this.notificacionesWsSelected = res.data._embedded.lista_partys[0].filter(e =>  res.data._embedded.lista_partys[1].includes(e.notification_ws_id))
        this.dialogListadoServicioWhatsApi = true
        
      })

    },

    guardarNotificacionUser(){

      this.setUrl('lista-partys')
      this.setLoadingBtn(true)

      this.requestApi({
        method: "POST",
        data: {
          typeStore: "storeNotificacionWsUser",
          notificacionesWsSelected: this.notificacionesWsSelected.map(e => e.notification_ws_id),
          userLoginId: this.userNotificacionesWs
        },
      })
      .then(res => {
        
        this.setLoadingBtn(false)
        this.userNotificacionesWs = null
        this.dialogListadoServicioWhatsApi= false
        this.alertNotification({
            param:{
                html: res.data.res.msg
            }
        })

      })
    }

  },
  mounted() {
    this.setTitleToolbar();
    this.setMenu(this.menu);
  }

}

</script>

<style>
  .v-dialog {
    background-color: white;
  }
  .v-divider--inset {
    max-width: 100% !important;
    margin-left: 10px !important;
    border-color: rgb(212 212 212) !important;
  }
</style>
