<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="dataTable"
            class="elevation-1"
            :search="searchNotificaciones"
            item-key="notification_ws_id"
            :loading="loadingTable"
            :fixed-header="true"
            :hide-default-footer="true"
            disable-pagination
            dense
        >
            <template v-slot:top>
                <v-toolbar dark color="blue" height="60" flat>
                    <v-text-field 
                        v-model="searchNotificaciones" 
                        label="Buscar" 
                        :hide-details="true"
                        append-icon="mdi-magnify"
                    />
                    <v-spacer />
                    <v-btn small color="primary" @click="openFormNotificacion">
                        <v-icon>mdi-plus</v-icon> Nuevo
                    </v-btn>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            @click="openFormNotificacion(item)"
                            color="warning"
                        >
                            <v-icon >mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar notificación</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            @click="setStatusNotificacion(item)"
                            :color="`${item.thru_date ? 'success' : 'error'}`"
                            :loading="loadingBtn"
                        >
                            <v-icon>
                                {{ item.thru_date ? 'mdi-check' : 'mdi-delete-forever' }}
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>{{ item.thru_date ? 'Habilitar' : 'Deshabilitar' }} Notificación</span>
                </v-tooltip>
            </template>
            <template v-slot:footer>
                <v-col md="12" class="text-center">
                    <v-btn
                        color="default"
                        @click="$emit('closeDialogNotificacionesWs')"
                    >
                        <v-icon>mdi-cancel</v-icon> CERRAR
                    </v-btn>
                </v-col>
            </template>
        </v-data-table>
        <v-dialog
            v-model="dialogFormNotificacion" 
            persistent 
            max-width="700px"
        >
            <v-toolbar dense color="blue" dark flat>
                <v-toolbar-title>Nueva notificación</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn 
                    icon 
                    @click="closeDialogFormNotificacion"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-form ref="form_notificacion">
                    <v-text-field
                        v-model="description"
                        label="Descripción"
                        dense
                        class="text-center pb-2"
                        :rules="required"
                    />
                    <v-text-field
                        v-model="channel"
                        label="Canal"
                        dense
                        class="text-center pb-2"
                        :rules="required"
                    />
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-col class="text-center" cols="12">
                    <v-btn
                        color="primary"
                        @click="guardarNotificacion"
                        :loading="loadingBtn"
                        class="mx-1"
                    >
                        <v-icon>mdi-floppy</v-icon> GUARDAR
                    </v-btn>
                    <v-btn
                        color="default"
                        @click="closeDialogFormNotificacion"
                        class="mx-1"
                    >
                        <v-icon>mdi-cancel</v-icon> CERRAR
                    </v-btn>
                </v-col>
            </v-card-actions>
        </v-dialog>
    </div>
</template>


<script>

import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

export default {
    name: "NotificacionesWsComponent",
    data: () => ({
        dataTable: [],
        headers: [
            { text: "ID", align: "start", value: "notification_ws_id" },
            { text: "Notificación", align: "start", value: "description" },
            { text: "Canal", align: "start", value: "channel" },
            { text: "Actions", align: "start", value: "actions", align: "center" },
        ],
        required:[ v => !!v || 'El campo es requerido'],
        notificaciones:[],
        status: true,
        dialogFormNotificacion:false,
        searchNotificaciones:null,
        description:null,
        channel:null,
        notificacionWsId:null,
    }),
    computed: {
        ...mapState("master", ["loadingTable","loadingBtn"])
    },
    methods: {

        ...mapMutations("master", ["setUrl","setLoadingTable","setLoadingBtn"]),

        ...mapActions("master", ["requestApi", "alertNotification"]),

        getNotificationsWs() {

            this.setLoadingTable(true)

            this.dataTable = [];

            this.setUrl("lista-partys");

            this.requestApi({
                method: "GET",
                data: {
                    typeList: "NotificacionesWs",
                    status: this.status,
                }
            })
            .then(res => {
                console.log('res',res)
                this.dataTable = res.data._embedded.lista_partys[0]
                this.$emit('fetching', false)

            })
            .then(() => {

                this.setLoadingTable(false)
                
            })

        },

        guardarNotificacion(){

            this.setLoadingBtn(true)
            this.setUrl("lista-partys")

            this.requestApi({
                method: "POST",
                data: {
                    typeStore: "storeNotificacionWs",
                    channel: this.channel,
                    description: this.description,
                    notificacion_ws_id: this.notificacionWsId,
                }
            })
            .then(res => {
               
                this.getNotificationsWs()
                this.closeDialogFormNotificacion()
                this.setLoadingBtn(false)
                this.alertNotification({
                    param:{
                        html: res.data.res.msg
                    }
                })

            })

        },

        openFormNotificacion(item){
           
            if(item){

                this.notificacionWsId = item.notification_ws_id
                this.description = item.description
                this.channel = item.channel

            }

            this.dialogFormNotificacion = true

        },

        closeDialogFormNotificacion(){

            this.dialogFormNotificacion = false
            this.$refs.form_notificacion.reset()

        },

        setStatusNotificacion({notification_ws_id}){
    
            this.setUrl("lista-partys")
            this.setLoadingBtn(true)

            this.requestApi({
                method: "POST",
                data: {
                    typeStore: "updateStateNotificacionWs",
                    notification_ws_id
                }
            })
            .then(res => {
                console.log('res',res)
                this.setLoadingBtn(false)
                this.getNotificationsWs()
                this.alertNotification({
                    param:{
                        html: res.data.res.msg
                    }
                })

            })
            
        }

    },
    mounted(){

        this.getNotificationsWs()

    }

}

</script>